import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import LoginDialog from './LoginDialog';
import { GET } from '../../../Commons/Utils/fetch';
import dayjs from 'dayjs';
import {
  Button,
  Collapse,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from 'styled-components';

const Header = styled.div.attrs({})`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 700;
  font-size: 1.125rem;
  justify-content: center;
  width: 100%;
  max-width: 793px;
  margin: 0 auto;
  position: relative;

  & > strong {
    margin-right: 0.5rem;
  }

  @media print {
    display: none;
  }
`;
const DateContainer = styled.div.attrs({})`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .MuiSelect-select {
    padding: 0.25rem 0.5rem;
    outline: none;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }

  .mr-2 > div {
    margin-right: 0.25rem;
  }
`;
const DataContainer = styled.div.attrs({})`
  width: 100%;
  max-width: 793px;
  margin: 0 auto;
`;
const DataHeader = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
const DataContent = styled.div.attrs({})`
  padding: 0.5rem 1rem;
`;

const getKRW = (number) =>
  Math.abs(number) > 0
    ? number?.toLocaleString('ko-KR', {
        style: 'currency',
        currency: 'KRW',
      })
    : '-';

const SettlementDetail = () => {
  // TODO: 로그인 여부 확인, 운영자일 경우 전체 확인,
  // TODO: 로그인되지 않았거나 운영자가 아닐 경우, 튜터의 전화번호 인증 프로세스 진행
  const token = localStorage.getItem('eje_tutor_token');
  const [isVerified, setIsVerified] = useState(false);
  const [tutorInfo, setTutorInfo] = useState(null);

  useEffect(async () => {
    if (token) {
      const response = await GET({
        url: '/tutor/verify',
        header: {
          EJE_TUTOR_KEY: token,
        },
      });
      const { success } = response;
      setTutorInfo(response.info);
      setIsVerified(success);
    } else {
      setIsVerified(false);
    }
  }, [token]);

  const [year, setYear] = useState(sessionStorage.getItem('year') || dayjs().subtract(1, 'month').year());
  const [month, setMonth] = useState(sessionStorage.getItem('month') || dayjs().subtract(1, 'month').month() + 1);

  useEffect(() => {
    sessionStorage.setItem('year', year);
    sessionStorage.setItem('month', month);
  }, [year, month]);

  const getSettlements = useCallback(async () => {
    try {
      const response = await GET({
        url: `/tutor/settlements/${year}/${month}`,
        header: {
          EJE_TUTOR_KEY: token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }, [year, month, token]);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (isVerified) {
      getSettlements()
        .then((res) => {
          const { success, data } = res;
          if (!success) {
            throw new Error('정산 내역을 불러오는데 실패했습니다.');
          }
          console.log(data);
          setData(data);
        })
        .catch((err) => {
          console.error(err);
          setData(null);
        });
    }
  }, [year, month, isVerified]);

  const ManualRow = ({ data, dataAll }) => {
    return (
      <Fragment>
        <TableRow
          key={data.id}
          sx={{
            '& > *': { height: '3rem', fontSize: '0.75rem' },
            '&:hover': { backgroundColor: '#eeeeee' },
          }}
        >
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{data.listen_type}</TableCell>
          <TableCell style={{ minWidth: '200px' }}>{data.title}</TableCell>
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{data.lectureTime}</TableCell>
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{data.lectureTimePrice}</TableCell>
          <TableCell
            style={
              data.commission > 0
                ? { whiteSpace: 'nowrap', fontWeight: 900, color: 'blue', textAlign: 'right' }
                : { textAlign: 'center', color: 'gray' }
            }
          >
            {getKRW(data.commission)}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const Row = ({ data, dataAll }) => {
    const { histories } = dataAll;
    const [open, setOpen] = useState(false);
    const currentHistories = useMemo(() => histories.filter((history) => history.title === data.title), [histories]);
    const handleOpen = () => {
      setOpen(!open);
    };
    console.log('data: ', data);
    return (
      <Fragment>
        <TableRow
          key={data.id}
          sx={{
            '& > *': { height: '3rem', fontSize: '0.75rem' },
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#eeeeee' },
          }}
          onClick={handleOpen}
        >
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {data.listen_type === 'online'
              ? '온라인'
              : data.listen_type === 'offline'
              ? '오프라인'
              : data.listen_type === 'foreigner'
              ? '외국인'
              : data.listen_type === 'roundOnline'
              ? '온라인(기수제)'
              : data.listen_type === 'consulting'
              ? '컨설팅'
              : data.listen_type}
          </TableCell>
          <TableCell style={{ minWidth: '200px' }}>{data.title}</TableCell>
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{data.count}</TableCell>
          <TableCell
            style={
              data.amount > 0
                ? {
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                    color: 'royalblue',
                  }
                : { textAlign: 'center', color: 'gray' }
            }
          >
            {getKRW(data.amount)}
          </TableCell>
          <TableCell
            style={
              Math.abs(data.currentCancelAmount) > 0
                ? {
                    whiteSpace: 'nowrap',
                    color: 'orangered',
                    textAlign: 'right',
                  }
                : { textAlign: 'center', color: 'gray' }
            }
          >
            {getKRW(data.currentCancelAmount)}
          </TableCell>
          <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {data.feeRate ? `${data.feeRate}%` : ''}
          </TableCell>
          <TableCell
            style={{ whiteSpace: 'nowrap', textAlign: 'center', color: data.commissionRate > 0 ? 'black' : 'gray' }}
          >
            {data.commissionRate ? `${data.commissionRate}%` : '-'}
          </TableCell>
          <TableCell
            style={
              data.commission > 0
                ? { whiteSpace: 'nowrap', fontWeight: 900, color: 'blue', textAlign: 'right' }
                : { textAlign: 'center', color: 'gray' }
            }
          >
            {getKRW(data.commission)}
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: '#eeeeee', '& > *': { fontSize: '0.75rem' } }}>
          <TableCell style={open ? { padding: '0 2rem 1rem 20%' } : { padding: '0 2rem 0 20%' }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {/*<strong>세부내역</strong>*/}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ '& > *': { fontSize: '0.75rem', whiteSpace: 'nowrap', textAlign: 'center' } }}>
                    <TableCell>No</TableCell>
                    <TableCell>결제일시</TableCell>
                    <TableCell>거래형태</TableCell>
                    <TableCell>결제금액</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentHistories?.map((history, index) => (
                    <TableRow
                      key={history.id}
                      sx={{ '& > *': { fontSize: '0.75rem', whiteSpace: 'nowrap', textAlign: 'center' } }}
                    >
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                        {history.createdDatetime.split(' ')[0]}
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          color: history.status === 'paid' ? 'royalblue' : 'orangered',
                        }}
                      >
                        {history.status === 'paid' ? '결제' : history.status === 'cancelled' ? '환불' : history.status}
                      </TableCell>
                      <TableCell
                        style={
                          history.currentAmount > 0
                            ? {
                                whiteSpace: 'nowrap',
                                textAlign: 'right',
                                color: history.status === 'paid' ? 'royalblue' : 'orangered',
                                paddingRight: '3rem',
                              }
                            : { textAlign: 'center', color: 'gray' }
                        }
                      >
                        {getKRW(history.currentAmount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const years = useMemo(() => {
    const years = [];
    for (let i = dayjs().subtract(1, 'month').year(); i >= 2022; i--) {
      years.push(i);
    }
    return years;
  }, []);

  return isVerified ? (
    <div className="w-full">
      <Header>
        <strong>{tutorInfo.name} 강사님</strong>
        <DateContainer>
          <div className="mr-2">
            <Select value={year} onChange={(e) => setYear(e.target.value)} size="small">
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            년
          </div>
          <div className="mr-2">
            <Select value={month} onChange={(e) => setMonth(e.target.value)} size="small">
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
            월 정산 내역
          </div>
        </DateContainer>
        <Button
          size="small"
          color="warning"
          sx={{
            fontSize: '0.75rem',
            position: 'absolute',
            right: 0,
          }}
          variant="contained"
          onClick={() => window.print()}
        >
          인쇄
        </Button>
      </Header>
      {data && (data.organized.length > 0 || data.manualOrganized.length > 0) ? (
        <DataContainer>
          <DataHeader>
            <TableContainer
              style={{
                border: '2px solid black',
                width: '100%',
                borderRadius: '0.5rem',
                overflow: 'hidden',
                boxShadow: '0.25rem 0.25rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.3)',
              }}
            >
              <Table size="small">
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      style={{
                        textAlign: 'center',
                        fontWeight: 900,
                        fontSize: '1.5rem',
                        position: 'relative',
                        width: '100%',
                        padding: '1rem 0',
                        borderBottom: '1px solid black',
                      }}
                    >
                      <div
                        className="logo"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: 0,
                          transform: 'translateY(-50%)',
                          width: '100%',
                          height: '70%',
                          backgroundImage: `url('/images/logo.png')`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                        }}
                      ></div>
                      <strong>
                        {year}년 {month}월 강의료 정산 내역
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} style={{ width: '90%', border: 'none' }}>
                      수신인&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {data?.tutors[0]?.name}
                    </TableCell>
                    <TableCell
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        borderLeft: '1px solid black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      정산일자: {dayjs(data.createdAt).format('YYYY-MM-DD')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ border: 'none' }}></TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        border: '1px solid black',
                        backgroundColor: 'lightblue',
                      }}
                    >
                      사업자번호
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      style={{
                        whiteSpace: 'nowrap',
                        borderBottom: '1px solid black',
                        borderTop: '1px solid black',
                      }}
                    >
                      138-85-33405
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ border: 'none' }}>
                      발신&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주)케이랩컴퍼니
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        border: '1px solid black',
                        backgroundColor: 'lightblue',
                      }}
                    >
                      회&nbsp;&nbsp;&nbsp;사&nbsp;&nbsp;&nbsp;명
                    </TableCell>
                    <TableCell colSpan={2} style={{ whiteSpace: 'nowrap', borderBottom: '1px solid black' }}>
                      (주)케이랩컴퍼니
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ border: 'none' }}>
                      H&middot;P&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 010-6274-9391
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        border: '1px solid black',
                        backgroundColor: 'lightblue',
                      }}
                    >
                      대&nbsp;&nbsp;&nbsp;표&nbsp;&nbsp;&nbsp;자
                    </TableCell>
                    <TableCell colSpan={2} style={{ whiteSpace: 'nowrap', borderBottom: '1px solid black' }}>
                      김형규, 이상환
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} style={{ border: 'none' }}>
                      e-Mail&nbsp;&nbsp;&nbsp;&nbsp;: baeksi@k-lab.me
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        border: '1px solid black',
                        borderBottom: 'none',
                        backgroundColor: 'lightblue',
                      }}
                    >
                      주&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;소
                    </TableCell>
                    <TableCell colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                      서울특별시 광진구 자양로 129 8층
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DataHeader>
          <DataContent>
            <TableContainer
              sx={{
                border: 'black solid 2px',
                borderRadius: '0.5rem',
                boxShadow: '0.25rem 0.25rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.3)',
              }}
            >
              <Table sx={{ width: '100%', overflow: 'hidden' }} size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      '& > th': {
                        fontSize: '0.875rem',
                        textAlign: 'center',
                        fontWeight: 900,
                        borderBottom: 'double 3px darkgray',
                        whiteSpace: 'nowrap',
                      },
                    }}
                  >
                    <TableCell>구분</TableCell>
                    <TableCell>강의명</TableCell>
                    <TableCell>거래수</TableCell>
                    <TableCell>
                      결제
                      <br />
                      금액
                    </TableCell>
                    <TableCell>
                      환불
                      <br />
                      금액
                    </TableCell>
                    <TableCell>
                      수수료
                      <br />
                      기준
                    </TableCell>
                    <TableCell>정산율</TableCell>
                    <TableCell>
                      정산
                      <br />
                      금액
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.organized.map((item) => (
                    <Row key={item} data={item} dataAll={data} />
                  ))}
                  {data.manualOrganized?.length <= 0 && (
                    <TableRow style={{ borderTop: 'solid 2px black', height: '3rem' }}>
                      <TableCell
                        colSpan={7}
                        style={{
                          textAlign: 'center',
                          fontWeight: 900,
                          fontSize: '0.875rem',
                          color: 'black',
                        }}
                      >
                        강사 정산료
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          fontWeight: 900,
                          fontSize: '0.875rem',
                          color: 'blue',
                        }}
                      >
                        <strong>{getKRW(data.totalCommission)}</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </Table>
            </TableContainer>

            {data.manualOrganized?.length > 0 && (
              <TableContainer
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  marginTop: '1rem',
                  border: 'black solid 2px',
                  borderRadius: '0.5rem',
                  boxShadow: '0.25rem 0.25rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.3)',
                }}
              >
                <Table
                  sx={{
                    width: '100%',
                    height: '100%',
                    tableLayout: 'fixed',
                    flex: 1,
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        width: '100%',
                        '& > th': {
                          fontSize: '0.875rem',
                          textAlign: 'center',
                          fontWeight: 900,
                          borderBottom: 'double 3px darkgray',
                          whiteSpace: 'nowrap',
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    >
                      <TableCell>구분</TableCell>
                      <TableCell>강의명</TableCell>
                      <TableCell>시수</TableCell>
                      <TableCell>시간 당 강사료</TableCell>
                      <TableCell>정산 금액</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.manualOrganized.map((item) => (
                      <ManualRow key={item} data={item} dataAll={data} />
                    ))}
                    <TableRow style={{ borderTop: 'solid 2px black', height: '3rem' }}>
                      <TableCell
                        colSpan={4}
                        style={{
                          textAlign: 'center',
                          fontWeight: 900,
                          fontSize: '0.875rem',
                          color: 'black',
                        }}
                      >
                        강사 정산료
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          fontWeight: 900,
                          fontSize: '0.875rem',
                          color: 'blue',
                        }}
                      >
                        <strong>{getKRW(data.totalCommission)}</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </TableContainer>
            )}

            <strong style={{ display: 'block', marginTop: '1rem', textSize: '0.75rem', color: 'red' }}>
              ※ 실제 강사료 입금액은 정산서 상 [강사 정산료]에서 원천징수분 3.3%를 제한 금액입니다.
            </strong>
          </DataContent>
        </DataContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4rem  1rem',
          }}
        >
          정산 내역이 없습니다.
        </div>
      )}
    </div>
  ) : (
    <LoginDialog open={true} onClose={() => {}} setIsVerified={setIsVerified} setTutorInfo={setTutorInfo} />
  );
};

export default SettlementDetail;
